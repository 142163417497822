<template>
    <div class="contenedor">
        <rutaPagina :volver="false" :ruta="ruta" />

        <div class="row glass"  >

            <tituloPagina texto="Críterios de selección" />

            <form class="row">
                <div class="col-lg-4 grupo-label">
                    <label for="fecha_inicio" class="form-label">Período</label>
                    <div class="input-group mb-3">
                        <input type="date" id="fecha_inicio" class="form-control fecha-inicio" placeholder="Fecha inicio" aria-label="Fecha inicio">
                        <span class="input-group-text spam-hasta">hasta</span>
                        <input type="date" id="fecha_fin" class="form-control fecha-fin" placeholder="Fecha fin" aria-label="Fecha fin">
                    </div>
                </div>

                <div class="col-lg-4 grupo-label">
                    <label for="estado" class="form-label">Año gravable</label>
                    <select id="estado" class="form-select form-select seleccion" aria-label=".form-select-sm example">
                        <option value="0">2021</option>
                        <option value="1">2020</option>
                        <option value="2">2019</option>
                    </select>
                </div>

                <div class="col-lg-4 grupo-label">
                    <label for="informacion_adiciona" class="form-label">Comentarios</label>
                    <div class="input-group mb-3">                        
                        <input type="text" class="form-control input-buscar" placeholder="" aria-label="Username" aria-describedby="basic-addon1">
                        <button type="button" class="btn btn-primary input-group-text btn-buscar">Generar certificado</button>
                    </div>                        
                </div>

                
            </form>

        </div>

    </div>
</template>

<script>
import rutaPagina from '@/components/RutaPagina'
import tituloPagina from '@/components/Titulopagina'

export default {
    name: 'Certificado retencion',
    setup(props) {

        const ruta = [
            { nombre : 'Certificados'},
            { nombre : 'Certificado de retenciones'},
        ]

        return{
            ruta,
        }
    },
    components : {
        rutaPagina,
        tituloPagina,
    }
}
</script>