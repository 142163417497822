<template>
    <div class="col-md-12 titulo-pagina">
        <i></i>
        <h2>{{ texto }}</h2>
    </div>
</template>

<script>
export default {
    name:'Titulo',
    props: {
        texto: { type: String, required: true },
    },
    setup(props) {

    }
}
</script>

<style scoped>

</style>