<template>
    <div>
        
        <div class="row fondo-header" >
            <div class="box-opcion-menu" >
                <i class='bx bx-menu' ></i>
            </div>
            <hr>
            <div class="tirulo-ruta">
                <i @click="regresar()" v-if="volver" class='bx bx-left-arrow-alt' ></i>
                <h2>{{ texto }}</h2>
            </div>
            <nav class="col-md-12 ruta" style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li v-for="rta in ruta" :key="rta.nombre" class="breadcrumb-item active" aria-current="page">
                        <router-link v-if="rta.ruta != null && rta.ruta != ''" :to="rta.ruta" class="color-link-defecto">{{ rta.nombre }}<slot /></router-link>
                        <a v-if="rta.ruta == null || rta.ruta == ''">{{ rta.nombre }}</a>
                    </li>
                </ol>
            </nav>
        </div>

    </div> 
</template>  

<script>
import { useRouter, useRoute } from 'vue-router'
import { onMounted } from "vue";

export default {
    name:'RutaPagina',
    props : {
        texto: { type: String, default:'Ruta' },
        volver: { type: Boolean, default: true },
        ruta : { type: Array, required: true }
    },
    setup(props) {
        const router = useRouter()

        const regresar = () => {
            //router.go(-1)
            router.back()
        }

        const iniciarMenu = () => {   
            let sideNav = document.querySelector('.sidebar');
            let sideNavBtn = document.querySelector('.bx-menu');
            sideNavBtn.addEventListener("click", (e) => {
                sideNav.classList.toggle("close");
            })
        }

        onMounted(iniciarMenu);

        return{
            regresar,
        }
    }
}
</script>

<style scoped>
.header-titulo{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}
.box-opcion-menu{
    width: 2rem;
    height: 2rem;
    background: var(--ng-primario);
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
    border-radius: 3px;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.box-opcion-menu i{
    color: #ffffff;
    font-size: 1.7rem;
}
.fondo-header {
    width: 100%;
    background: rgba( 255, 255, 255, 1 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 0 0 10px 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.tirulo-ruta{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}
.tirulo-ruta h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0px;
}
.tirulo-ruta i {
    font-size: 1.6rem;
}
.tirulo-ruta i:hover {
    background: var(--ng-primario);
    color: #ffffff;
    border-radius: 50%;
    font-size: 1rem;
    padding: 0.3rem;
    cursor: pointer;
}
.breadcrumb-item {
    font-size: 0.85rem;
    cursor: pointer;
}
hr{    
 background: #c3c3c5;
}
.color-link-defecto {
    color: inherit;
    text-decoration: none;
}
.color-link-defecto:hover{
    color: rgb(7 95 254);
}
</style>